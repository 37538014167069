import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय ५ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय ५ वा' num='05'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । जलावाचून जीवन । शुष्क होणार जीवन । वरुणास शतशः वंदन ॥ १ ॥
तैसेच कृपेवाचून । जे जे होते क्रीयमाण । जे जे होते लेखन । ते नुसते शब्दांचे घडण ॥ २ ॥
जेणे गणामहाराज विनवे । कृपाशिर्वाद असावे । ऐसे ग्रंथ लेखन व्हावे । सार्‍यांनी चकित व्हावे ॥ ३ ॥
प्रत्येकास वाटावे । कृपाशीष असावे । जीवन सुसंगत व्हावे । मनोरथ पूर्ण व्हावे ॥ ४ ॥
शेगावी समर्थांभोवती । भक्तगण अवती भोवती । प्रसन्न होण्या गुरूमूर्ती । नाना यत्न करती ॥ ५ ॥
सारे भोवती गजानना । समर्थांना न निवांतपणा । कैसे खपणार त्यांच्या मना । त्यांना स्थिर रहावेना ॥ ६ ॥
जो नाही लोकांच्या कौतुकावर । भाळत जनस्तुतीवर । जो मूळचाच जागृत असणार । कैसा अधोगत होणार ॥ ७ ॥
समर्थ चुकवू लागले गर्दीला । टाळू लागले प्रश्र्नोत्तरीला । शोधू लागले ऐशा स्थळाला । निवांतपणा लाभायला ॥ ८ ॥
कोठेही जावे नि रहावे । बंधन कुणाचे नसावे । मुक्तपणे वावरावे । बंदिस्त कधी नसावे ॥ ९ ॥
हे तत्व ज्याने अनुसरले । त्याला मायेचे बंधन न उरले । ज्याने मायेला ओळखले । त्यानेच शाश्वत अशाश्वत जाणले ॥ १० ॥
पुढारी गर्दी करतात । भाडोत्री सारे जमवतात । समर्थ गर्दी टाळतात । एकांतवास पत्करतात ॥ ११ ॥
समर्थांना गर्दी खपत नसे । त्यांच्या कृतीवरुन पटत असे । एका ठिकाणी स्थिर नसे । वायुवेगे धावत असे ॥ १२ ॥
ज्याने काही न ठेवले स्वतःचे । त्याला महत्व काय गर्दीचे ? । नाही महत्व कौतुकाचे । नच महत्व जन निंदेचे ॥ १३ ॥
सच्चिदानंदात रमणार । त्यातच तल्लीन होणार । त्याच्याच तंद्रीत रहाणार । गर्दीस न महत्व देणार ॥ १४ ॥
समर्थांनी नजर चुकविली । शेगांवभुमी सोडली । पिंपळगावची वाट धरली । अरण्याची वाट धरली ॥ १५ ॥
पिंपळगावचे लोक धन्य । ज्यांना लाभे गजानन । भूमी झाली पुण्यपावन । समर्थांचे लागता चरण ॥ १६ ॥
समर्थांचे चरण जेथे । सत्संग घडे तेथे । सदाचरणाशी नाते जडते । सत्वृत्ती जागृत होते ॥ १७ ॥
जीवनात महत्व सत्संगाचे । जेथे सत् असणार । तेथे असत् कसे टिकणार ? । दोघे न एके ठिकाणी नांदणार ॥ १८ ॥
सत् + चित् + आनंद । तेणेच सच्चिदानंद । जेव्हा चित्ताचा सत्‌शी संबंध । तेव्हाच मिळतो आनंद ॥ १९ ॥
जेथे असते सत्‌संगत । तेथे नांदतो भगवंत । तेणे आनंद बागडत । स्वयेच येतो धावत ॥ २० ॥
जीवाचा एकच ध्यास । मज हवा भगवंत सहवास । ऐसे करावे यत्नास । भगवंत लाभावा जीवास ॥ २१ ॥
ऐसा यत्न करावा । भगवंत जोडावा । जीव त्यात रमवावा । जीव सार्थक करावा ॥ २२ ॥
जोडण्याने मिळतो आनंद । त्यातच भेटतो परमानंद । सुख आणि आनंद । ह्यात फार मोठा भेद ॥ २३ ॥
सुखाचा संबंध दुःखाशी । एका सुखात अनेक दुःख राशी । आनंदाचा संबंध परमात्म्याशी । आनंद येता परमानंद दाराशी ॥ २४ ॥
सुख हे दुःखयुक्त । आनंद परमानंदयुक्त । हे तत्व समर्थ ओळखतात । म्हणुनच ते समर्थ ठरतात ॥ २५ ॥
ज्यांच्या कृतीत सामर्थ्य । तेच ठरती समर्थ । सामान्य जन करती अनर्थ । वेळ दवडती व्यर्थ ॥ २६ ॥
मिथ्यास मानती सत्य । सत्यास मानती मिथ्य । सारेच मायेला भुलतात । मायेतच गुरफटतात ॥ २७ ॥
तेच असत्य असते । असंख्य भोग भोगणे होते । एका कृतीवर दुसरी कृती होते । एका चुकीमुळे दुसरी चूक होते ॥ २८ ॥
चूक कळता सुधारता येते । कुणास चूक कळते ? । ज्याला जीवनातले मर्म कळते । त्यालाच चूक लक्षात येते ॥ २९ ॥
जो असतो सावध । कैसा रहाणार बेसावध ? । अनुसंधाने असतो सावध । अन्यथा होतो बेसावध ॥ ३० ॥ 
जो साधे अनुसंधान । तोच असतो सावधान । जो असतो सावधान । अंतरत्म्याशी अवधान ॥ ३१ ॥
महाराजांनी तत्व जाणले । गर्दीला सहज टाळले । शेगाव सोडून गेले । पिंपळगावासी पोहोचले ॥ ३२ ॥
पिंपळगावच्या शिवारात । जुने पुराणे शिवमंदीर त्यात । हेमाडपंती शिवमंदीरात । समर्थ बसले गाभार्‍यात ॥ ३३ ॥
पद्मासन घालून बैसले । काळवेळेचे भान न राहिले । भूक तहान विसरले । समर्थांचे न काही उरले ॥ ३४ ॥
होते समाधी अवस्थेत । समाधी नाही लावावी लागत । लागते एकरूपतेत । एकरूपताच ठरते श्रेष्ठ ॥ ३५ ॥
होते सर्व पूर्व पुण्याईवर । सत्कृत्याची पडली भर । पुण्याईस येतो बहर । फळे त्याची बहारदार ॥ ३६ ॥
सत्कृत्ये ह्या जन्मी करावी । फळे त्याची पुढिल जन्मी भोगावी । ऐशी कृती आचरावी । ती सत्कृतीच ठरावी ॥ ३७ ॥
ऐसे कर्म करावे । पुण्यफळ पदरी पडावे । दुराचारे वागू नये । हे तत्व सोडू नये ॥ ३८ ॥
कर्मांचा संबंध पूर्व कर्मांवर । पूर्व संचितावर । एका कर्मात दुसरे कर्म होणार । कर्म फळ भोगावे लागणार ॥ ३९ ॥
ऐसे असावे पूर्व संचित । जीव राहील आनंदित । ऐसे नसावे संचित । जेणे जीव व्यथित ॥ ४० ॥
समर्थ सर्व जाणतात । सत्वृत्तीतच रमतात । सत्कृत्येच आचरतात । धर्म रक्षण करतात ॥ ४१ ॥
समर्थ होते समाधी अवस्थेत । एका वेगळ्या तंद्रीत । एका वेगळ्या आनंदात । आपणांस न मिळे वाचनात । ४२ ॥
खरा आनंद स्वानुभवात । नच नुसत्या वर्णनात । नच नुसत्या वाचण्यात । नच नुसत्या ऐकण्यात ॥ ४३ ॥
जीवनात स्वानुभवाला महत्व फार । जो निसर्गाचा नियम असणार । तो समर्थ कैसा मोडणार ? । समर्थांना देखिल कर्मे करावी लागणार ॥ ४४ ॥
समर्थांच्या कर्मात । नि आपल्या कर्मात । फरक आहे मोठा त्यात । एक निःस्वार्थात दुसरा स्वार्थात ॥ ४५ ॥
स्वार्थात होते अधोगती । निःस्वार्थात मिळते सद्‌गती । स्वार्थात न कुणी तरती । स्वार्थात न परमार्थ भेटी ॥ ४६ ॥
जैसी ज्याला संगत । तैसी कर्मे घडतात । तेणे रहावे सत्संगतीत । जेणे रहाल सत्वृत्तीत ॥ ४७ ॥
सायंकाळच्या समयास । गुराखी येत होते घरास । वाटेत डोकावले शिव मंदीरास । बघते झाले समर्थांस ॥ ४८ ॥
पिंपळगावची पोरे जमली । योग्यास जागे करू लागली । समर्थांची ऐशी तंद्री लागली । पोरे न भंग करू शकली ॥ ४९ ॥
पोरांनी फूलहार आणला । महाराजांच्या गळ्यात घातला । भोजनास भाकरीचा तुकडा ठेवला । जो तो बघण्यास उत्सुकलेला ॥ ५० ॥
वाटले पोरांस हा जीव उपाशी । खाईल तो भाकरीशी । पाणी दिले प्यावयासी । करू लागले जे जे मनासी ॥ ५१ ॥
बराच काळ लोटला । सूर्य ही अस्तास गेला । अंधःकार झाला । पोरांचा जीव घराकडे लागला ॥ ५२ ॥
वडील मंडळींना पोरे घाबरलेली । घरची ओढ लागलेली । घरची वाट धरली । विचारचक्रे सुरू झाली ॥ ५३ ॥
पाऊले पडत होती घराकडे । चित्त गुंतले समर्थांकडे । पाऊले पडत होती पुढे । परी विचार मागिलकडे ॥ ५४ ॥
पोरे घरी परतली । देहभान हरपलेली । भूक तहान विसरलेली । प्रत्येकाची हीच तर्‍हा झाली ॥ ५५ ॥
वाट बघती वडील मंडळी । पोरे घरात परतली । शुभंकरोती म्हणती झाली । भोजनाची वेळ झाली ॥ ५६ ॥
काय जेवणार चवीने ? । घास घेत होती हाताने । जेवत होती मुखाने । परी वेगळ्याच विचाराने ॥ ५७ ॥
जेवणात लक्ष लागेना । जाणवले सकळांना । रात्री झोप येईना । चैन न पडे त्यांच्या मना ॥ ५८ ॥
सर्वांचा स्वभाव नसतो सारखा । हाताच्या पाच बोटांसारखा । स्वभाव ज्याचा होता जैसा । आचरला मनोधर्म तैसा ॥ ५९ ॥
काहिंनी घरी सांगितले । काहिंनी लपवले । सवयी प्रमाणे केले । मूळस्वभावास आचरले ॥ ६० ॥
काहिंना संस्कार ऐसे । ते घरात बोलणार कैसे ? । घरात सर्वच बोलायचे नसते । हेच मनावर ठसलेले असते ॥ ६१ ॥
पोरे रात्री झोपी गेली । दृष्ये पुढे येऊ लागली । समर्थ स्वारी दिसू लागली । निद्रा न त्यांस आली ॥ ६२ ॥
रात्र सरली दिनकर आला । दिनचर्येचा आरंभ झाला । ज्यांस वृत्तांत समजला । तो दुसर्‍यांस बोलता झाला ॥ ६३ ॥
जो तो उत्सुक दर्शनाला । पोरांसवे निघाला । आले शिवमंदिराला । समर्थांना पहाण्याला ॥ ६४ ॥
महाराज बसलेले त्याच जागेवर । गळ्यात होता फूलहार । भाकर तुकडा चतकोर । पाणीही तैसेच खापरभर ॥ ६५ ॥
जैसा काल पाहिलेला । तैसाच आज बैसलेला । भाकरीस न स्पर्श केला । भूक तहानही विसरलेला ॥ ६६ ॥
समाधी उतरल्यावर । हा बोलेल साधुवर । कुणी म्हणती पालखी आणा सत्वर । मिरवू त्यास गांवभर ॥ ६७ ॥
एक पालखी आणली । फूल हाराने सजवलेली । त्यात ठेवली गुरूमाऊली । समाधी न भंग पावली ॥ ६८ ॥
गावातील नरनारी । भजनाचा गजर करी । वाजंत्र्याच्या तालावरी । नाचू लागले नरनारी ॥ ६९ ॥
गावात पालखी निघाली । बाल गोपाल सभोवताली । आश्चर्ये वदू लागली । “ऐसी मूर्ती न पाहिली” ॥ ७० ॥
मिरवणूक आली गावात । मारूतीच्या मंदीरात । आणले सद्गुरूनाथ । बसवले थाटात ॥ ७१ ॥
तो ही दिवस तैसाच गेला । लोकांनी विचार केला । केव्हा उतरेल समाधीला ? । लोकांनी नामगजर केला ॥ ७२ ॥
काही समय जाता । देहभानात आले सद्गुरूनाथा । आनंद झाला समस्तां । चरणी ठेवता माथा ॥ ७३ ॥
त्यांची समाधी उतरली । स्वारी नामजपात रंगलेली । दृष्टी सभोवताली गेली । बघती झाली भक्त मंडळी ॥ ७४ ॥
नैवेद्याची पाने आली । समर्थांस अर्पण झाली । वार्ता सभोवताली पसरली । गर्दी पहाता पहाता झाली ॥ ७५ ॥
ज्याने टाळले गर्दीला । पुन्हा बघितले गर्दीला ।टाळू न शकले उपाधीला । विधात्याच्या लीलेला ॥ ७६ ॥
ईश्वरी सत्तेपुढे न काही चाले । हे येथे खरे ठरले । समर्थ न गर्दी टाळू शकले । जरी त्यांनी शेगाव सोडले ॥ ७७ ॥
पिंपळगावचे रहिवासी । मंगळवारच्या दिवशी । गेले शेगांवासी । आठवड्याच्या बाजारासी ॥ ७८ ॥
जाते झाले बाजारास । वदते झाले गावकर्‍यांस । कुणी नंगा बाबा गावास । आला पिंपळगावास ॥ ७९ ॥
कपड्याचा ठिकाणा नाही । आपल्याच तंद्रीत राही । नावागावाचा पत्ता नाही । स्वतःच्या तंद्रीत पडून राही ॥ ८० ॥
वेड्यापिस्यास्तव राही । स्वतःच्याच धुंदीत राही । खरे काहीच कळत नाही । ते कुणाला सांगत नाही ॥ ८१ ॥
वेड्यासारखा वागत नाही । तोंडाने पुटपुटत राही । किंचाळणे माहित नाही । पोरांना मारत नाही ॥ ८२ ॥
कळला वृत्तांत शेगावात । चर्चेचा विषय गावात । कोण असावा साधुसंत । कुतुहल सार्‍यांच्या मनात ॥ ८३ ॥
जेव्हा बंकट जाणता झाला । तो न स्वस्थ बैसला । महाराजांच्या भेटीला । बंकट अधीर झाला ॥ ८४ ॥
क्षणभरात विचार आला । गजाननाचीच लीला । म्हणे जाऊ पिंपळगावाला । पाहू गजाननाला ॥ ८५ ॥
तो पिंपळगावात गेला । योगी पुरुषास बघू लागला । आश्चर्याचा धक्का बसला । महाराजांस ओळखू शकला ॥ ८६ ॥
समर्थांसमोर उभा राहिला । समर्थ चरणी माथा ठेवला । नाना तर्‍हे विनवू लागला । चला स्वामी शेगावाला ॥ ८७ ॥
बंकटास उत्तम संस्कार । तेणे धार्मिक सदाचार । महाराजांचा धरला कर । हाच जीवनी आधार ॥ ८८ ॥
महाराज म्हणाले त्यास । “घाबरतो आदरातिथ्यास । घाबरतो तुझ्या वैभवास । नको कुणाचे घर आम्हास ॥ ८९ ॥
डावे उजवे न खपे आम्हास । मेजवानी न प्रिय आम्हास । शुद्ध भाव प्रिय आम्हास । नको थारा लौकिकास ॥ ९० ॥
नको विनवू मला । पिंपळगावात राहू दे मला । नको शेगांव मला । भितो तेथल्या गर्दीला ॥ ९१ ॥
नको टाकू बंधनात । आम्ही मुळचेच बंधनमुक्त । नको पडू भानगडीत । तू रमावे व्यापारात ॥ ९२ ॥
रहावे तुझ्या पत्नी समवेत । तू रहावे संसारात । तुम्ही शोभता प्रपंचात । आम्ही असतो योगक्रियेत” ॥ ९३ ॥
महाराजांस परत नेण्यास । गाडी आणली पिंपळगावास । बंकटे विनविले गावकर्‍यांस । येऊद्या समर्थ शेगांवास ॥ ९४ ॥
महाराज हवे होते सर्वांना । पिंपळगावकर्‍यांना । तैसेच शेगांवकरांना । कुणीही त्यांना सोडेना ॥ ९५ ॥
बंकट गावकर्‍यांस वदला । महाराज राहतील माझ्या घराला । पूर्वीही होते माझ्याच घराला । जरी आज पिंपळगावाला ॥ ९६ ॥
हे घर न माझे राहिले । माझे माझे काही न उरले । माझे घर न परके राहिले । सारे महाराजांचेच झाले ॥ ९७ ॥
आपण वरचेवर यावे । महाराजांना भेटावे । परी आता मला जाऊ द्यावे । मला न आपण अडवावे ॥ ९८ ॥
सारे गाव खोळंबले । त्याने न कोणाचे ऐकले । समर्थांस गाडीत बसवले । शेगांवात आणले ॥ ९९ ॥
शेगांवकर महाराजांना । काही केल्या सोडेनात त्यांना । नाना तर्‍हेच्या विनवण्या । गावकरी करती त्यांना ॥ १०० ॥
महाराजांची कर्मभूमी । शेगाव हीच त्यांची भूमी । जरी बदलते झाले भूमी । परी शेगावकर नव्हते कमी ॥ १०१ ॥
विधाता जे निश्चित करणार । ते महाराज कैसे बदलणार ? । प्राक्तनात लिहिलेलेच खरे ठरणार । काही वेळेस अपवाद ठरणार ॥ १०२ ॥
गुरूमूर्ती शेगावात आली । पहाता पहाता वार्ता पसरली । पुन्हा गर्दी जमू लागली । भक्त मंडळी आनंदित झाली ॥ १०३ ॥
बंकटास सारी म्हणू लागली । “वा बंकट! कमाल केली । शेगावभुमी पावन केली । तुझ्यामुळेच गुरूमूर्ती लाभली ॥ १०४ ॥
धन्य धन्य तू बंकट । महाराज तुझे ऐकतात । पूर्वपुण्याईच पदरात । जेणे सद्गुरू लाभतात” ॥ १०५ ॥
सारे त्यांना जपत होते । सर्व जण तैनातीत होते । प्रत्येकास महाराज हवे होते । म्हणुनच तसे वागत होते ॥ १०६ ॥
दुसर्‍यांस हवे हवेसे वाटावे । ऐसे जीवन जगावे । सर्वांचे आपण व्हावे । परी अलीप्तेपणे वागावे ॥ १०७ ॥
असा जो वागतो । तोच सर्वांना आवडतो । त्याचाच शब्द झेलला जातो । त्याचाच शब्द आधार ठरतो ॥ १०८ ॥
रोज नित्यनवे भक्तगण । रोज नित्य नवे प्रश्र्न । गुरू कृपाशिष घेऊन । प्रसंगी जात होते तरून ॥ १०९ ॥
महाराज शेगावकर झाले । शेगावकर महाराजमय झाले । आपपर भाव लुप्त झाले । सर्व जण “मी” स विसरले ॥ ११० ॥
महाराजांची सोबत लाभली । तेणे सर्वांच्या गाली खळी खुलली । जीवनाचा आधार मानू लागली । श्रद्धेने शब्द झेलू लागली ॥ १११ ॥
जेथे महाराज मुक्कामाला । तेथे आनंद वस्तीला । जो तो अनुभवु लागला । दुसर्‍यांस सांगू लागला ॥ ११२ ॥
जो तो आनंदाचा भुकेला । जेणे येत होता दर्शनाला । एरव्ही सवड कुणाला ? । चेहेर्‍यावरचे भाव बोलके करू लागला ॥ ११३ ॥
सारे व्यवस्थित चालले होते । शेगावकर मनोमन खुष होते । आनंदाचे साम्राज्य होते । महाराजांवर अवलंबून होते ॥ ११४ ॥
महाराजांनी पुनः एकदा चुकवले । प्रातःकाळी अडगावास निघाले । वायुवेगे पळाले । कुणी न त्यांस थोपवू शकले ॥ ११५ ॥
महाराज फक्त शेगावचे नव्हते । तर ते सर्वांचे होते । ते मुळचेच धुनी होते । वेळोवेळी पटवत होते ॥ ११६ ॥
ते वायुवेगे पळायचे । पहाता पहाता अदृष्य व्हायचे । पहाता पहाता पुन्हा दिसायचे । सारे बळ योग सामर्थ्याचे ॥ ११७ ॥
योग लीलेने त्यांनी कुठेही जावे । कुठेही पडून रहावे । कधी ध्यानस्थ दिसावे । कधी सहज बसलेले दिसावे ॥ ११८ ॥
एके ठिकाणी राहू नये । ऐसे ज्यास वाटावे । बंकटाचे घर कैसे पत्करावे ? । हेच समर्थ कृतीतून पटवे ॥ ११९ ॥
योगी जगू शकतो अन्न पाण्या वाचून । परी नच ईश्वरी सत्तेवाचून । शेगाव जाते झाले सोडून । गेले अडगावी निघून ॥ १२० ॥
वैशाख महिन्याचे दिवस होते । ऊन रखरखत होते । योग्यास चटके बसत नव्हते । वायुवेगे जात होते ॥ १२१ ॥
आजुबाजूस झाडेवेली सुकलेली । पाण्याला सदा मुकलेली । तहानेने तडफडू लागली । काही गळून पडलेली ॥ १२२ ॥
हिरवळीचे नाव नाही । सावलीची सोबत नाही । निसर्गाची कृपा नाही । पर्जन्याचे नाव नाही ॥ १२३ ॥
सारे शुष्क वातावरण । प्रसन्नतेचे न नावनिशाण । प्रत्यक्ष सूर्य नारायण । आग ओकत होता मुखातुन ॥ १२४ ॥
सार्‍यांनाच काढले होरपळून । धरती न सुटली त्यातून । धरतीसही न झाले सहन । होरपळत होता रवीकिरण ॥ १२५ ॥
धरती तप्त झालेली । लाही लाही झालेली । शांततेची परिसीमा गाठली । सहनशक्तीची मर्यादा संपली ॥ १२६ ॥
एका योग्याला कीव आली । त्या साठीच त्याने धाव घेतली । परमेश्वरास विननणी केली । धरती साठी आर्जव केली ॥ १२७ ॥
महाराजांना तहान लागली । चहुकडे दृष्टी फिरविली । पाण्याने त्यांना पाठ दाखवली । तशीच पुढची वाट धरली ॥ १२८ ॥
देहातल्या पाण्याशिवाय । न दिसे कुठे पाण्याची सोय । तहानेने व्याकूळ होता जीव । कोण करणार पाण्याची सोय ? ॥ १२९ ॥
गरज शोधाची जननी । नुसतेच म्हणता पाणी पाणी । हाती न लागणार पाणी । न मिळणार प्रयत्नांवाचुनी ॥ १३० ॥
समर्थे इकडे तिकडे बघितले । पाणी न कुठे दिसले । आळवल्यावाचून न मिळे । हे योग्यासच कळे ॥ १३१ ॥
आळवावे लागते योग्य वेळेला । धावून यावे लागते ईश्वराला । भक्ताच्या रक्षणाला । जैसी माता तान्हुल्याच्या रडण्याला ॥ १३२ ॥
तेवढ्यात दृष्टी गेली शेतावर । समर्थ मुळचेच हुशार फार । शेतात पाणी असणार । त्यावाचून न शेती होणार ॥ १३३ ॥
प्रसंग पडता बाका । ईश्वरास मारा हाका । धावून येतो मारता हांका । रक्षितो प्रसंग बांका ॥ १३४ ॥
समर्थे शेताकडे पाहिले । ईश्वराचे स्मरण केले । येथेच आपणास पाणी मिळे । ऐसे त्यांस वाटले ॥ १३५ ॥
महाराज गेले शेतावर । मालक पाटिल भास्कर । राबत होता शेतावर । भरत होता उदर ॥ १३६ ॥
तो अकोली गावचा । वृत्तांत सांगितला पाण्याचा । गावात दुष्काळ पाण्याचा । जो तो शोधात पाण्याच्या ॥ १३७ ॥
जैसे नेत्याचे नुसते भाषण । जनांस ठेवतो ठणठण । तैसे भास्कराचे भाषण । पाण्याचे न नाव निशाण ॥ १३८ ॥
जो प्राप्त वैभव माझेच म्हणतो । अप्पलपोटी असतो । तेथे मायेचा ओलावा नसतो । तो निर्दयपणे वागतो ॥ १३९ ॥
जो सारे ईश्वराचेच मानतो । तोच दुसर्‍यास देतो । देण्याने न साठा संपतो । परी तो वृद्धिंगत होतो ॥ १४० ॥
ज्ञान दिल्याने वाढते । प्रेम दिल्याने वाढते । भक्ती परोपकारात वाढते । अप्पलपोटीपणात हानी होते ॥ १४१ ॥
जो हे नियम आचरतो । तोच जीवन जगू शकतो । तो जीवनी धन्य ठरतो । जो जीवनी आदर्श ठरतो ॥ १४२ ॥
निसर्गास ओळखावे । ईश्वरी तत्व जाणावे । सारे बांधव मानावे । माझे माझे म्हणू नये ॥ १४३ ॥
अज्ञानी भास्करास काय कळणार ? । तो गाड्याला बैल जुंपणार । शेतीवाडी करणार । स्वतःचीच तुंबडी भरणार ॥ १४४ ॥
जो सृष्टीचा क्रम न जाणणार । हे तत्व कैसे पटणार ? । अनुभवाने जे पटणार । तेच मनावर ठसणार ॥ १४५ ॥
भास्करही होता तसाच । तो वदला समर्थांस । “पुसू नको तू पाण्यास । पाणी नाही आमच्या गावास” ॥ १४६ ॥
महाराज वदले भास्करास । “काय सांगतो आम्हास ! । पिण्या इतुके देण्यास । नको नकारू पाण्यास ॥ १४७ ॥
तहानेने व्याकुळ । नको बडबडू वायफळ । पाण्यानेच तहान शमेल । नच भाषणाने शमेल” ॥ १४८ ॥
भास्करास काय धडे कळणार ? । सारे स्वानुभवातच कळणार । जो तीव्रता अनुभवणार । तोच समंजस होणार ॥ १४९ ॥
चटका सोसल्याने चटका जाणवतो । बाकीचा कोरडा उपदेश ठरतो । जो प्रसंगात होरपळतो । तो दुसर्‍याचे दुःख जाणतो ॥ १५० ॥
भास्कर म्हणे समर्थांस । “नंगा वेडा पिसा दिसतोस । जाऊ नको तिकडच्या विहीरीस । मुळीच पाणी नाही विहीरीस ॥ १५१ ॥
जेथे पाणी नाही थेंबभर । काय तुझी तृष्णा भागणार ? । नावालाच आहे विहीर । कोरडी ठणठणीत साचार” ॥ १५२ ॥
महाराज गेले विहीरीवर । पाणी नव्हते थेंबभर । उभे राहिले क्षणभर । दृष्टिक्षेप केला विहीरीवर ॥ १५३ ॥
टक लावून तेथे बघितले । विहीरीचे चित्त आकर्षिले । विहीरीतच पाणी दडलेले । क्षणात तिने मोकळे केले ॥ १५४ ॥
समर्थांच्या अंगी सामर्थ्य । योगबळाच्या सामर्थ्यात । विहीर भरली क्षणात । देवाण घेवाण आली अस्तित्वात ॥ १५५ ॥
सारे देण्याघेण्यावर चालते । पेरलेलेच उगवते । काही न वाया जाते । हे म्हणणे सार्थक ठरते ॥ १५६ ॥
ह्या जन्मी ऐसे पेरावे । पुढिल जन्मीही उपयुक्त ठरावे । बीज कशाचे पेरावे ? । प्रेम जिव्हाळा परोपकाराचे पेरावे ॥ १५७ ॥
भास्करास सार कळला । अनुभवाने तो जागा झाला । महाराजांस शरण आला । समर्थ चरणी माथा ठेवला ॥ १५८ ॥
तो विसरला बायको पोरे । उदर निर्वाहाचे साधन सारे । गूढ कळले सारे । मूढत्व गेले सारे ॥ १५९ ॥
भास्कर झाला भास्कर । पळुन गेला अज्ञान तस्कर । उदयास येता भास्कर । काही न करे त्यास तिमिर ॥ १६० ॥
ऐसे गुरूवर लाभले । भास्कराचे जीणे धन्य झाले । खर्‍या अर्थाने चरण धरले । समर्थे अंतर्ज्ञानाने जाणले ॥ १६१ ॥
समर्थ वदले भास्करास । भरपूर पाणी विहीरीस । भरपूर पिकव शेतचार्‍यास । भरपूर पाणी गुर ढोरांस ॥ १६२ ॥
तुझे भाग्य आले उदयाला । कुणी न आटवणार विहीरीला । हेच साधन उदरनिर्वाहाला । भरपूर पिकव शेतचार्‍याला ॥ १६३ ॥
मी केवळ निमित्त । जेणे विहीर भरली क्षणात । जे जे तुझे पूर्वसंचित । तेच एकवटून आले क्षणात ॥ १६४ ॥
भास्कर वदला त्यावर । फुकटचा राबलो शेतावर । काही न पदरात आजवर । नुसत्या देहाने केला संसार ॥ १६५ ॥
जेणे प्रपंचाचा वाटतो भार । सदा चिंतेचा आजार । तेणे होतो बेजार । कुणास सारे सांगणार ? ॥ १६६ ॥
सारेच भरकटलेले । सारेच हताश झालेले । सारेच त्रस्त झालेले । अंगातले त्राण गेलेले ॥ १६७ ॥
कोण विव्हळणे ऐकणार ? । कोण आसरा देणार ? । जो तो माघार घेणार । कुणी न पदरचे देणार ॥ १६८ ॥
आम्ही सारेच ऐसे वागतो । प्रपंचातच गुरफटतो । गुरू तूच आम्हास जागृत करतो । जेणे आम्ही भानावर येतो ॥ १६९ ॥
सार्‍यांनी मला लाथाडले । कर्मफळ भोगले । तुम्हीच जवळ केले । भाग्य उदयास आले ॥ १७० ॥
ठेवतो गुरूचरणी माथा । तुम्हीच माझे माता पिता । तुम्हीच आता त्राता । वाटे तुमच्यासवे यावे आता ॥ १७१ ॥
समर्थे योगबळे उत्पन्न केले जलास । बोध झाला भास्करास । ऐसाच होवो सकळांस । तेथेच पांचवा अध्याय पूर्णत्वास ॥ १७२ ॥
गणामहाराज सांगतो येथे । सत्संगतीने जे मिळते । तेच जीवनात टिकते । तेणे जीवन सुसंगत होते ॥ १७३ ॥
त्यास लागते नामाची सोबत । तेणे प्रपंच परमार्थयुक्त । प्रारब्ध भोग न जाणवत । सारे वातावरण आनंदयुक्त ॥ १७४ ॥


॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य  पंचमोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
